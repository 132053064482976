import React, { useEffect, useRef } from 'react';
import './css/mobileCoinItem.css'
import tweeterSVG from './img/tweeter.svg'
import telegramSVG from './img/telegram.svg'
import wwwSVG from './img/web.svg'
import copy from './img/copy.svg'
import sun from './img/sun.svg'
import tronscan from './img/tronscan.svg'
import bubblemapSVG from './img/bubble.svg'
import bubblemapSVG_black from './img/bubble_black.svg'
import premium from './img/premium_icon.png'
import { FaChartBar } from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast';
import { getTokenExplorerUrl } from 'helpers/token_urls_helper';
import { SWAP_PLATFORM_TOKEN_URL } from 'constants/settings';
import {BACKEND_URL} from 'constants/settings';

const MobileCoinItem = props => {
    const data = props.data

    function secondsToTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const hoursString = hours.toString().padStart(2, '0');
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        const minutesString = minutes.toString().padStart(2, '0');
        const remainingSecondsFinal = remainingSeconds % 60;
        const secondsString = remainingSecondsFinal.toFixed(0).padStart(2, '0');
      
        return `${hoursString}:${minutesString}:${secondsString}`;
    };

    const handleRedirect = (url) => {
        window.open(url, '_blank');  
    };

    const handleClickBuy = (tokenAdress) => {
        const url = SWAP_PLATFORM_TOKEN_URL + tokenAdress;
        window.open(url, '_blank');
    };

    const handleClickCa = (tokenAdress) => {
        const url = getTokenExplorerUrl(tokenAdress);
        window.open(url, '_blank');
    };

    const holdersColorBgValue = (holder, holder_list) => {
        let color = ''
        let bg = ''

        const holders = holder_list.length ? parseFloat(holder_list.length) : 0
        const procent = holder
        const procentInt = procent ? parseFloat(procent).toFixed(0) : null
    
        if(procentInt === 0){
          bg = "transparent"
          color = '#000'
        }else if(procentInt <= 5){
          bg = '#479a70';
          color = '#fff';
        }else if(procentInt > 5 && procentInt <= 10){
          bg = '#d8b744';
          color = '#000';
        }else if(procentInt > 10 && procentInt <= 20){
          bg = '#da7124';
          color = '#fff';
        }else if(procentInt > 20){
          bg = '#dc1f1d';
          color = '#fff';
        }

        return {
            color: color, 
            bg: bg, 
            value: holders
        }
    };

    const holderTopColor = (holder) => {
        let color = ''
        const procentHolde = holder
        const procentHoldeInt = procentHolde ? parseFloat(procentHolde).toFixed(0) : null

        if(procentHoldeInt === null){
          color = "transparent"
        }else if(procentHoldeInt <= 5){
          color = '#479a70';
        }else if(procentHoldeInt > 5 && procentHoldeInt <= 10){
          color = '#d8b744';
        }else if(procentHoldeInt > 10 && procentHoldeInt <= 20){
          color = '#da7124';
        }else if(procentHoldeInt > 20){
          color = '#dc1f1d';
        }
        return color
    };
    
    const devColor = (procent) => {
        let color = '#fff';
        let devValue = parseFloat(procent).toFixed(2);
    
        if(devValue === "0.00"){
          color = '#dc1f1d';
        } else if (devValue < 5) {
          color = '#479a70';
        } else if (devValue > 5 && devValue <= 10) {
          color = '#d8b744';
        } else if (devValue > 10 && devValue <= 15) {
          color = '#da7124';
        } else if (devValue > 15) {
          color 
          = '#dc1f1d';
        }
        return color;
    }

    const tokenCreatorColor = (num_created_tokens_by_wallet) => {
        let color = '#fff';
        let created_tokens = num_created_tokens_by_wallet;
    
        if (created_tokens <= 1) {
          color = '#479a70';
        } else if (created_tokens >= 2 && created_tokens <= 3) {
          color = '#d8b744';
        } else if (created_tokens > 3) {
          color = '#dc1f1d';
        }
        return color
    }

    function aiMMdetector(wartosc) {
        const min = 10;
        const max = 50;
        if (wartosc < min) {
            return "0";
        }else if(wartosc > 50){
          return "100";
        }
        const procent = ((wartosc - min) / (max - min)) * 100;
        return `${Math.round(procent)}`;
    }

    const tronLinkW = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="15px" version="1.1" viewBox="0 0 43.2 36">
            <g>
                <g id="Warstwa_1">
                <g className="cls-3">
                    <g>
                    <path fill="#fff" d="M0,7L17.4,0l17.4,7-17.4,7L0,7Z"/>
                    <path fill="#fff" d="M1.5,7v21.6l-1.5-.6V7h1.5Z"/>
                    <path fill="#fff" d="M18.2,13v21.7l-.7.3-.8-.3V13h1.5Z"/>
                    <path fill="#fff" d="M19.5,34.2l-1.3.5-.7.3-.8-.3-1.2-.5L1.5,28.6l-1.5-.6v-1.6l1.5.6,15.2,6.1.8.3.7.3,1.3.5Z"/>
                    <path fill="#fff" d="M0,7v1.6l18.2,7.3v-1.6L0,7Z"/>
                    <path fill="#fff" d="M43.1,3.7l-25.8,10.4.6,1.4,25.2-10.1v-1.6Z"/>
                    <path fill="#fff" d="M43.1,10.2l-25.8,10.4.6,1.4,25.2-10.1v-1.6Z"/>
                    <path fill="#fff" d="M43.1,16.7l-25.8,10.4.6,1.4,25.2-10.1v-1.6Z"/>
                    <path fill="#fff" d="M43.1,23.2v1.6l-23.6,9.5-1.3.5-.7.3-.8-.3-1.2-.5,1.2-.5.8-.3.7-.3,24.9-10Z"/>
                    </g>
                </g>
                </g>
            </g>
            </svg>
        )
    }

    return (
        <div className='token'>
            <div className='start-time'>
                <span>Start:</span>
                {secondsToTime(data.token.create_time_elapsed)}
            </div>
            {
                data.token.has_image 
                ? <div className='tokenImg' style={{backgroundImage: `url(${BACKEND_URL}images/256_${data.token.contract_address}.png)`}}></div>
                : <div className='noneImg'>N/A</div>
            }


            <div className='tab-body'>

                <div className='socila-on-photo'>
                    {data.token.twitter_url && 
                        <button 
                            onClick={()=>handleRedirect(data.token.twitter_url)}  
                            className={'table-btn'} 
                            style={{background:'transparent', padding: 0}}
                            data-tooltip-id="create_timestamp" data-tooltip-content={'Twitter'}
                        >
                            <img src={tweeterSVG} alt=""/>
                        </button>
                    }
                    {data.token.website_url && 
                        <button 
                            onClick={()=>handleRedirect(data.token.website_url)} 
                            className={'table-btn'} 
                            style={{background:'transparent', padding: 0}}
                            data-tooltip-id="create_timestamp"
                            data-tooltip-content={'Website'}
                        >
                            <img src={wwwSVG} alt=""/>
                        </button>
                    }
                    {data.token.telegram_url && 
                        <button 
                            onClick={(e)=>{
                            handleRedirect(data.token.telegram_url)
                        }}  
                        className={'table-btn'} 
                        style={{background:'transparent', padding: 0}}
                        data-tooltip-id="create_timestamp"
                        data-tooltip-content={'Telegram'}
                            >
                            <img src={telegramSVG} alt=""/>
                        </button>
                    }
                </div>

                <div className='token-title-row'>
                    <a className="text-dark" href={getTokenExplorerUrl(data.token.contract_address)} target="_blank" rel="noopener noreferrer" style={{cursor: 'pointer'}}>
                        <div className='token-name'>{data.token.contract_name} <span style={{fontSize: '13px'}}>({data.token.contract_short_name})</span></div>
                    </a>
                    <div className='token-social'>

                        {<button 
                            className={'table-btn tronlink-btn'} 
                            onClick={()=>handleClickCa(data.token.contract_address)} 
                            style={{background:'transparent', padding: 0}}
                            data-tooltip-id="create_timestamp"
                            data-tooltip-content={'TronScan'}
                        >
                            {tronLinkW()}
                            TronLink
                        </button>}

                        {<button 
                            className={'table-btn sun-btn'} 
                            onClick={()=>handleClickBuy(data.token.contract_address)} 
                            style={{background:'transparent', padding: 0}}
                            data-tooltip-id="create_timestamp"
                            data-tooltip-content={'Buy'}
                        >
                            <img src={sun} alt=""/> 
                            Buy
                        </button>}

                    </div>
                </div>
                <div className='token-row'>
                    <div className='token-bonding'>
                        <div className='token-label'>Bonding</div>
                        <div className='token-value'>{parseFloat(data.data.bonding_progress).toFixed(1)}% </div>
                    </div>
                    <div className='token-market-cap'>
                        <div className='token-label'>Market cap</div>
                        <div className='token-value'>
                            <button
                                className="table-btn"
                                onClick={() => {
                                    props.tog_standard();
                                    props.setmodal_data(data.data.prices)
                                }}
                            >
                                {
                                    data.data.market_cap_usd 
                                    ? <>{`$${(parseFloat(data.data.market_cap_usd)/1000).toFixed(1)}k` } <FaChartBar /></>
                                    : '-'
                                }                                 
                            </button>
                        </div>
                    </div>
                </div>
                <div className='bonding-progress' style={{marginBottom: '5px'}}>
                    <div className="progres-bar-tab">
                        <div className="point" style={{width: `${Math.min((parseFloat(data.data.bonding_progress)).toFixed(0), 100)}%`}}></div>
                    </div>
                </div>
                <div className='token-row seperator'>
                    <div className='token-holders'>
                        <div className='token-label'>Holders</div>
                        <div className='token-value'>
                            <button
                                className="table-btn"
                                onClick={() => {
                                    if(holdersColorBgValue(data.data.holder, data.data.holders_list).value !== 0){
                                        props.tog_standard();
                                        props.setmodal_data(data)                    
                                    }
                                }}
                                style={{backgroundColor: holdersColorBgValue(data.data.holder, data.data.holders_list).bg, color: holdersColorBgValue(data.data.holder, data.data.holders_list).color, minWidth: '35px', border: '0'}}
                            >
                                {holdersColorBgValue(data.data.holder, data.data.holders_list).value}
                            </button>
                        </div>
                    </div>
                    <div className='token-top'>
                        <div className='token-label'>Top</div>
                        <div className='token-value' style={{color: holderTopColor(data.data.holder)}}>
                            {data.data.holder}%
                        </div>
                    </div>
                </div>

                <div className='token-row seperator'>
                    <div className='token-tx'>
                        <div className='token-label'>Tx</div>
                        <div className='token-value'>{data.data.swaps}</div>
                    </div>
                    <div className='token-dev'>
                        <div className='token-label'>Dev</div>
                        <div className='token-value'>
                            <div style={{ color: devColor(parseFloat(data.data.owner.percent).toFixed(2)), fontWeight: '600' }}>
                                {parseFloat(data.data.owner.percent).toFixed(2)}% 
                            </div>
                            
                            <button
                                className='btn copy'
                                onClick={() => props.copyToClipboard(data.token.creator_address)}
                                style={{ background: 'transparent', padding: 0 }}
                                data-tooltip-id="create_timestamp"
                                data-tooltip-content={'Copy Token Creator Address'}
                            >
                                <img src={copy} alt="" />
                            </button>
                        </div>
                    </div>
                </div>

                <div className='token-row seperator'>
                    <div className='token-buy-sell'>
                        <div className='token-label'>Buy/Sell</div>
                        <div className='token-value'>{(parseFloat(data.data.buysell_last_5_minutes)/1000).toFixed(2)}k</div>
                    </div>
                    <div className='token-vol'>
                        <div className='token-label'>Vol</div>
                        <div className='token-value'>${(parseFloat(data.data.volume_usd)/1000).toFixed(1)}k</div>
                    </div>
                </div>

                <div className='token-row'>
                    <div className='token-buy-duplicates'>
                        <div className='token-label'>Duplicates</div>
                        <div className='token-value'>
                            <div>{data.data.num_with_same_name}</div>
                            <div>({data.data.num_bonded_with_same_name})</div>
                        </div>
                    </div>
      
                    <div>
                        <div className='token-label'>Dev Crtd.</div>
                        <div className='token-value'>
                            <div style={{color: tokenCreatorColor(data.data.num_created_tokens_by_wallet)}}>
                                {data.data.num_created_tokens_by_wallet}
                            </div>
                        </div>
                    </div>                    
                    
                </div>

                {(props.shouldShow  === 'MASTER' || props.shouldShow  === 'ADVANCED') && (
                    <div className='token-premium'>
                        {/* <div className='premium-sign'>Premium</div> */}
                        <div className='token-row'>
                            <div className='token-buy-new'>
                                <div className='token-label' style={{width: '35%'}}>
                                    New:
                                </div>
                                <div className='token-value'>
                                    {(parseFloat(data.data.wallets_stats.new)).toFixed(2)}%
                                </div>
                            </div>
                            <div className='token-ai-mm-detector'>
                                <div className='token-label' style={{width: '58%'}}>
                                    AI MM:
                                </div>
                                <div className='token-value'>
                                    {parseFloat(aiMMdetector(data.data.MM.num_buys)).toFixed(2)}%
                                </div>
                            </div>
                        </div>
                        <div className='token-row'>
                            <div className='token-buy-traders'>
                                <div className='token-label' style={{width: '35%'}}>Traders:</div>
                                <div className='token-value'>
                                    {(parseFloat(data.data.wallets_stats.user)).toFixed(2)}%
                                </div>
                            </div>
                            <div className='token-ai-mm-scammers'>
                                <div className='token-label' style={{width: '58%'}}>Scammers:</div>
                                <div className='token-value'>
                                    {(parseFloat(data.data.wallets_stats.scam)).toFixed(2)}%
                                </div>    
                            </div>
                        </div>
                        <div className='token-row-one'>

                            {props.shouldShow  === 'MASTER' && (
                 
                                <button 
                                    className='btn' 
                                    onClick={()=>{
                                        props.tog_iframeModal()
                                        props.setBubbleMapAddress(data.token.contract_address)
                                    }}
                                >
                                    <img src={bubblemapSVG_black} width={30} alt=""/>
                                    BubbleMaps
                                </button>
          
                            )}                                
                            
                        </div>
                    </div>  
                                      
                )}


            </div>
        </div>
    );
};

export default MobileCoinItem;
