import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutMode,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changePreloader,
  changeTopbarTheme,
  showRightSidebarAction,
} from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

import "../../components/CommonForBoth/rightbar.scss";

import { useDispatch, useSelector } from "react-redux";
import { updateTelegramUsername } from "../../store/auth/profile/actions";
import { useState, useRef, useEffect } from "react";
//constants
import {
  layoutModeTypes,
  topBarThemeTypes,
} from "../../constants/layout";
import { useApi } from "common/useApi";
import { toast } from "react-hot-toast";
import { DEV_WALLETS } from "constants/settings";

const RightSidebar = props => {
  const { setTelegramUsernameQuery, telegramInviteUrl } = useApi()
  const dispatch = useDispatch()
  const telegramInputRef = useRef(null)
  const telegramUsername = useSelector(state => state.Profile.telegramUsername)
  const walletAddress = useSelector(state => state.Profile.walletAddress)

  async function joinTelegram(username){
      try {
        if(username[0] == '@'){
          username = username.slice(1)
        }

        await setTelegramUsernameQuery.mutateAsync(username);
        dispatch(updateTelegramUsername(username))
        window.open(telegramInviteUrl, '_blank');
        toast.success('Telegram username updated successfully')
      } catch (error) {
        console.log('Error:', error);
        toast.error(error.response.data.message)
      }
  }

  useEffect(() => {
    if(telegramUsername != 'null' && telegramUsername != 'undefined'){
      telegramInputRef.current.value = telegramUsername
    }
  }, [telegramUsername])

  return (
    <React.Fragment>
      <div className="right-bar" id="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault()
                  props.showRightSidebarAction(false)
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">Settings</h5>
            </div>

            <hr className="my-0" />

            <div className="p-4">
              <hr className="mt-1" />
              <div className="radio-toolbar">
                <div style={{'display':DEV_WALLETS.find(x=>x==walletAddress) ? 'block' : 'none'}}>
                  <span className="mb-2 d-block">Telegram Channel</span>
                  <p className="text-muted mb-2">Join our Telegram channel to get live token updates.</p> 
                  <input type="text" className="form-control" placeholder="Username @" ref={telegramInputRef} />
                  <button type="submit" className="btn join-btn default mt-2 d-flex align-items-center justify-content-center gap-2"
                    onClick={
                      () => {

                        if(telegramInputRef.current.value.length == 0){
                          toast.error('Please enter a Telegram username')
                          return
                        }

                        joinTelegram(telegramInputRef.current.value)
                      }
                    }
                  ><div className="telegram-icon"><i className="mdi mdi-telegram"></i></div> Join</button>
                  <hr className="my-2" />
                </div>
                <span className="mb-2 d-block">Layouts Mode:</span>
                <input
                  type="radio"
                  id="radioLight"
                  name="radioLight"
                  value={layoutModeTypes.LIGHT}
                  checked={props.layoutModeType === layoutModeTypes.LIGHT}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayoutMode(e.target.value);
                    }
                  }}
                />
                <label className="me-1" htmlFor="radioLight">Light</label>
                <input
                  type="radio"
                  id="radioDark"
                  name="radioDark"
                  value={layoutModeTypes.DARK}
                  checked={props.layoutModeType === layoutModeTypes.DARK}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayoutMode(e.target.value);
                    }
                  }}
                />
                <label htmlFor="radioDark">Dark</label>
              </div>

              {/* <hr className="mt-1" />

              <div className="radio-toolbar">
                <span className="mb-2 d-block" id="radio-title">
                  Topbar Theme
                </span>
                <input
                  type="radio"
                  id="radioThemeLight"
                  name="radioTheme"
                  value={topBarThemeTypes.LIGHT}
                  checked={props.topbarTheme === topBarThemeTypes.LIGHT}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeTopbarTheme(e.target.value);
                    }
                  }}
                />
                <label className="me-1" htmlFor="radioThemeLight">Light</label>
                <input
                  type="radio"
                  id="radioThemeDark"
                  name="radioTheme"
                  value={topBarThemeTypes.DARK}
                  checked={props.topbarTheme === topBarThemeTypes.DARK}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeTopbarTheme(e.target.value);
                    }
                  }}
                />
                <label className="me-1" htmlFor="radioThemeDark">Dark</label>
                {props.layoutType === "vertical" ? null : (
                  <>
                    <input
                      type="radio"
                      id="radioThemeColored"
                      name="radioTheme"
                      value={topBarThemeTypes.COLORED}
                      checked={props.topbarTheme === topBarThemeTypes.COLORED}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeTopbarTheme(e.target.value);
                        }
                      }}
                    />
                    <label className="me-1" htmlFor="radioThemeColored">Colored</label>{" "}
                  </>
                )}
              </div> */}
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay"></div>
    </React.Fragment>
  );
};

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changePreloader: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  isPreloader: PropTypes.any,
  layoutType: PropTypes.any,
  layoutModeType: PropTypes.any,
  changeLayoutMode: PropTypes.func,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  topbarTheme: PropTypes.any,
  onClose: PropTypes.func,
};

const mapStateToProps = state => {
  return { ...state.Layout };
};

export default connect(mapStateToProps, {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader,
  showRightSidebarAction,
})(RightSidebar);
