import { EXPLORER_ADDRESS_URL, EXPLORER_TOKEN_URL, EXPLORER_TRANSACTION_URL } from 'constants/settings';

function getAddressExplorerUrl(address){
    return `${EXPLORER_ADDRESS_URL}${address}`
}

function getTokenExplorerUrl(tokenAddress){
    return `${EXPLORER_TOKEN_URL}${tokenAddress}`
}   

function getTransactionExplorerUrl(tx){
    return `${EXPLORER_TRANSACTION_URL}${tx}`
}

export { getAddressExplorerUrl, getTransactionExplorerUrl, getTokenExplorerUrl };