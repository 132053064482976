import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import './profile-menu.css'

import { FaRegUser } from "react-icons/fa";
// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

import { DEFAULT_AUTH } from 'constants/settings';

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const accessTier = sessionStorage.getItem('accessTier');
  const [username, setusername] = useState("Admin");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (DEFAULT_AUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        DEFAULT_AUTH === "fake" ||
        DEFAULT_AUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block tier-drop"
      >
        <DropdownToggle
          className={`btn header-item tier ${props.swipeView && props.widthScreen < 600 ? 'mobile' : '' }`}
          id="page-header-user-dropdown"
          tag="button"
          style={{color: '#fff'}}
        >
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {props.swipeView && props.widthScreen < 600 ? <i className="bx bx-user font-size-16 align-middle me-1" /> : accessTier}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile" disabled>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")} {props.swipeView && props.widthScreen < 600 ? '('+accessTier+')' : '' }
          </DropdownItem>
          <DropdownItem tag="a" href="/crypto-wallet" disabled>
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem>
          
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
