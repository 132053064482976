import { useQuery, useMutation  } from 'react-query';
import axios from 'axios'; // Import Axios
import { APIKEY, BACKEND_URL } from 'constants/settings';

export function useApi() {
  const authToken = sessionStorage.getItem('authToken');
  // const proxy = 'https://thingproxy.freeboard.io/fetch/'
  const proxy = ''

  const headers = {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': authToken
  };

  const getValidateAuth = async (requestData) => {
    const response = await axios.post(`${proxy}${APIKEY}/users/validate_auth`, requestData, { headers });
    return response.data;
  };
  const getValidateAuthQuery = useMutation((data) => getValidateAuth(data));

  const getTokens = async () => {
    const response = await axios.get(`${proxy}${APIKEY}/dashboard/latest`, { headers });
    return response.data;
  };
  const getTokensQuery = useQuery('getTokens', getTokens, {
    enabled: false,
  });
  
  const getTokensList = async (requestData) => {
    const response = await axios.post(`${proxy}${APIKEY}/dashboard/search`, requestData, { headers });
    return response.data;
  };
  const getTokensListQuery = useMutation((data) => getTokensList(data));
  
  const getTokensByName = async (requestData) => {
    const response = await axios.post(`${proxy}${APIKEY}/dashboard/by_name`, requestData, { headers });
    return response.data;
  };
  const getTokensByNameQuery = useMutation((data) => getTokensByName(data));

  const getTokensByHunt = async (requestData) => {
    const response = await axios.post(`${proxy}${APIKEY}/dashboard/hunt`, requestData, { headers });
    return response.data;
  };
  const getTokensByHuntQuery = useMutation((data) => getTokensByHunt(data));

  const getTokensDashbordStats = async () => {
    const response = await axios.get(`${proxy}${APIKEY}/dashboard/daily_stats`, { headers });
    return response.data;
  };
  const getTokensDashbordStatsQuery = useQuery('getTokensDashbordStats', getTokensDashbordStats, {
    enabled: false,
  });


  const setTelegramUsername = async (username) => {
    const response = await axios.post(`${proxy}${APIKEY}/users/telegram/set-username`, { username }, { headers });
    return response.data;
  };
  const setTelegramUsernameQuery = useMutation((data) => setTelegramUsername(data));


  const telegramInviteUrl = `${proxy}${APIKEY}/users/telegram/invite-link`

  return { 
    getValidateAuthQuery,
    getTokensQuery, 
    getTokensListQuery, 
    getTokensByNameQuery, 
    getTokensByHuntQuery, 
    getTokensDashbordStatsQuery,
    setTelegramUsernameQuery,
    telegramInviteUrl
  }
}
