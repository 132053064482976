import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, UPDATE_TELEGRAM_USERNAME, UPDATE_WALLET_ADDRESS } from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const updateWalletAddress = walletAddress => {
  sessionStorage.setItem('walletAddress', walletAddress);
  console.log('walletAddress', walletAddress)
  return {
    type: UPDATE_WALLET_ADDRESS,
    payload: { walletAddress },
  }
}

export const updateTelegramUsername = telegramUsername => {
  sessionStorage.setItem('telegramUsername', telegramUsername);

  return {
    type: UPDATE_TELEGRAM_USERNAME,
    payload: { telegramUsername },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
