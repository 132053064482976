import React from 'react';
import sun from './img/sun.svg'
import tronscan from './img/tronscan.svg'
import solscan_logo from './img/solscan_logo.png'
import pumpfun_logo from './img/pumpfun_logo.png'
import { useEffect } from 'react';
import { getTokenExplorerUrl } from '../../helpers/token_urls_helper';
import { SWAP_PLATFORM_TOKEN_URL, BLOCKCHAIN_PLATFORM } from 'constants/settings';
import { BLOCKCHAIN_PLATFORM_TRON, BLOKCHAIN_PLATFORM_SOLANA } from 'constants/constants';


const handleClickBuy = (tokenAdress) => {
    const url = SWAP_PLATFORM_TOKEN_URL + tokenAdress;
    window.open(url, '_blank');
  };

  const handleClickCa = (tokenAdress) => {
    const url = getTokenExplorerUrl(tokenAdress);
    window.open(url, '_blank');
  };

function getSwapPlatformLogo(){
  switch(BLOCKCHAIN_PLATFORM){
    case BLOCKCHAIN_PLATFORM_TRON:
      return sun;
    case BLOKCHAIN_PLATFORM_SOLANA:
      return pumpfun_logo;
    default:
      throw new Error('Invalid blockchain platform');
  }
}

function getExplorerLogo(){
  switch(BLOCKCHAIN_PLATFORM){
    case BLOCKCHAIN_PLATFORM_TRON:
      return tronscan;
    case BLOKCHAIN_PLATFORM_SOLANA:
      return solscan_logo;
    default:
      throw new Error('Invalid blockchain platform');
  }
}


function TokenLinks({tokenAddress}){
    return <>{<a 
        className={'table-btn'} 
        style={{background:'transparent', padding: 0}}
        data-tooltip-id="create_timestamp"
        data-tooltip-content={'Buy'}
        href={SWAP_PLATFORM_TOKEN_URL + tokenAddress}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img width={12} src={getSwapPlatformLogo()} alt=""/>
      </a>}

      {<a 
        className={'table-btn'} 
        style={{background:'transparent', padding: 0}}
        data-tooltip-id="create_timestamp"
        data-tooltip-content={'Explorer'}
        href={getTokenExplorerUrl(tokenAddress)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={getExplorerLogo()} width={12} alt=""/>
      </a>}</>
}

export default TokenLinks;
