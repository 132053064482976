export const DEFAULT_AUTH = process.env.REACT_APP_DEFAULTAUTH;
export const APIKEY = process.env.REACT_APP_APIKEY

export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME
export const WEBSITE_TITLE = process.env.REACT_APP_WEBSITE_TITLE
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const BLOCKCHAIN_PLATFORM = process.env.REACT_APP_BLOCKCHAIN_PLATFORM
export const EXPLORER_ADDRESS_URL = process.env.REACT_APP_EXPLORER_ADDRESS_URL
export const EXPLORER_TOKEN_URL = process.env.REACT_APP_EXPLORER_TOKEN_URL
export const EXPLORER_TRANSACTION_URL = process.env.REACT_APP_EXPLORER_TRANSACTION_URL

export const SWAP_PLATFORM_NAME = process.env.REACT_APP_SWAP_PLATFORM_NAME
export const SWAP_PLATFORM_URL = process.env.REACT_APP_SWAP_PLATFORM_URL
export const SWAP_PLATFORM_TOKEN_URL = process.env.REACT_APP_SWAP_PLATFORM_TOKEN_URL

export const DEV_WALLETS = ["TE4tY1rCjqUCBiBVPDvYyz8CYoSpD4kCYp","TVWeybCprQzTF2tSeNEnebAamcYMaSB5np","TAFuaoTm4Ketrf7vcQDfoB1RVUDhcKYU52"]